@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  height: 100vh;
  outline: none;
  margin: 0;
  padding: 0;
  background: #232737;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
